import AddIcon from "@/components/add-icon";
import EditIcon from "@/components/edit-icon";
import NoteIcon from "@/components/note-icon";
import SuccessIcon from "@/components/success-icon";
import TrashIcon from "@/components/trash-icon";
import ViewIcon from "@/components/view-icon";
import { colors } from "@/config/theme";
import useBranchConfig from "@/hooks/use-branch";
import useStyledMessage from "@/hooks/use-styled-message";
import queryClient from "@/utils/query-client";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployee,
} from "../api";
import type { CreateEmployee, Employee, UpdateEmployee } from "../types";
import lockIcon from "/lock.svg";

const { Header, Content } = Layout;

let isActive = true;

type ModalState = "create" | "read" | "update" | "delete";

interface ModalConfig {
  state: ModalState;
  itemId?: number;
  isOpen: boolean;
}

export default function EmployeeControl(): React.ReactElement {
  const { t } = useTranslation();
  const { contextHolder, open } = useStyledMessage();
  const form = Form.useForm()[0];

  const [modal, setModal] = useState<ModalConfig>({
    state: "read",
    isOpen: false,
  });

  const [page, setPage] = useState(1);
  const [supplierSelected, setSupplierSelected] = useState(false);

  const { branchConfig } = useBranchConfig();

  const position = localStorage.getItem("position");

  const createMutation = useMutation({
    mutationFn: createEmployee,
    onSuccess: async () => {
      setModal({ state: "read", isOpen: false });
      form.resetFields();
      void queryClient.invalidateQueries(["employees"]);
      open({
        type: "success",
        content: t("employee-added"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
      await refetch();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (data: { id: number; payload: UpdateEmployee }) => {
      const { id, payload } = data;
      await updateEmployee(id, payload);
    },
    onSuccess: async () => {
      void queryClient.invalidateQueries(["employees"]);
      setModal({ state: "read", isOpen: false });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteEmployee,
    onSuccess: async () => {
      setModal({ state: "read", isOpen: false });
      form.resetFields();
      void queryClient.invalidateQueries(["employees"]);
      open({
        type: "success",
        content: t("employee-deleted"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
      await refetch();
    },
  });

  const { data, refetch } = useQuery({
    queryKey: ["employees"],
    queryFn: async () => {
      const res = await getEmployees();
      return res;
    },
  });

  const employees = data?.results;

  const modalTitle = (() => {
    if (modal.state === "delete") return null;
    if (modal.state === "create") return t("add-employee");

    return t("employee");
  })();

  const showModal = (state: ModalState, id?: number): void => {
    if (typeof id === "undefined") {
      setModal({ state, isOpen: true });
    } else {
      setModal({ state, isOpen: true, itemId: id });
    }
  };

  const handleCancel = (): void => {
    setModal({ state: "read", isOpen: false });
    form.resetFields();
  };

  const handleDelete = (id: number): void => {
    deleteMutation.mutate(id);
  };

  const submitHandler = (values: CreateEmployee): void => {
    console.log(values);
    console.log(modal);

    if (modal.state === "create") {
      createMutation.mutate({
        ...values,
        is_active: values.is_active ?? isActive,
        username: values.username.split(" ").join(""),
        password: values.password.split(" ").join(""),
      });
    }

    if (modal.state === "update" && typeof modal.itemId !== "undefined") {
      updateMutation.mutate({
        id: modal.itemId,
        payload: {
          ...values,
          is_active: values.is_active ?? isActive,
        },
      });
      void refetch();
    }
  };

  const paginationProps = {
    defaultCurrent: page,
    total: 0,
  };

  const onPageChange: TableProps<Employee>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<Employee> = [
      {
        key: "full_name",
        dataIndex: "first_name",
        render(value: string) {
          return <span>{value}</span>;
        },
      },
      {
        key: "username",
        dataIndex: "username",
        render(value: string) {
          return <span>{value}</span>;
        },
      },
      {
        key: "position",
        dataIndex: "position",
        render(value: string) {
          return <span>{t(value)}</span>;
        },
      },
      {
        key: "is_active",
        dataIndex: "is_active",
        render(value: boolean, record) {
          return (
            <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
              <Switch
                defaultChecked={value}
                onChange={(val) => {
                  updateMutation.mutate({
                    id: record.id,
                    payload: { is_active: val },
                  });
                  void refetch();
                }}
              />
            </ConfigProvider>
          );
        },
      },
      {
        key: "see",
        render(_value, record) {
          return (
            <Button
              className="flex items-center text-[#5566ff] border-[#5566ff]"
              onClick={() => {
                showModal("read", record.id);
              }}
            >
              <ViewIcon />
              {t("view")}
            </Button>
          );
        },
        width: "0",
      },
      {
        key: "edit/delete",
        dataIndex: "id",
        render(value: number) {
          return (
            <div className="flex gap-4">
              <Button
                icon={<EditIcon />}
                className="bg-[#eef0ff] text-[#5566ff] border-none"
                onClick={() => {
                  showModal("update", value);
                }}
              />
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#FF1F00",
                  },
                }}
              >
                <Button
                  icon={<TrashIcon />}
                  className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                  onClick={() => {
                    showModal("delete", value);
                  }}
                />
              </ConfigProvider>
            </div>
          );
        },
        width: "0",
      },
    ];

    return allColumns;
  }, [t, updateMutation]);

  useEffect(() => {
    if (typeof modal.itemId !== "undefined") {
      const found = employees?.find((empl) => empl.id === modal.itemId);

      if (typeof found !== "undefined") {
        form.setFieldsValue({ ...found, password: found.open_password });
      }
    }
  }, [modal.itemId, employees, form]);

  return (
    <>
      {contextHolder}
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <h1 className="text-2xl font-semibold">{t("employee-control")}</h1>

        <div className="flex items-center">
          <Button
            type="primary"
            className="flex items-center"
            size="large"
            onClick={() => {
              showModal("create");
            }}
          >
            <AddIcon />
            {t("new-employee")}
          </Button>
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] px-[50px] py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-employees")}
                </span>
              </div>
            </div>
          )}
        >
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={employees}
            onChange={onPageChange}
            pagination={paginationProps}
            showHeader={false}
            loading={
              createMutation.isLoading ||
              updateMutation.isLoading ||
              deleteMutation.isLoading
            }
            className="custom-employee-table"
          />
        </ConfigProvider>
      </Content>

      <Modal
        title={modalTitle}
        open={modal.isOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={modal.state === "delete" ? undefined : 600}
        closeIcon={modal.state === "delete" ? null : undefined}
      >
        {modal.state !== "delete" ? (
          <Form
            name="employee-control-form"
            layout="vertical"
            onFinish={submitHandler}
            autoComplete="off"
            className="pt-6"
            form={form}
            requiredMark={false}
            disabled={modal.state === "read"}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={t("fio")}
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      min: 5,
                      whitespace: true,
                      message: t("field-min-5") ?? "",
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("job_title")}
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: t("required-field") ?? "",
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    options={[
                      { label: t("cashier"), value: "kassir" },
                      { label: t("courier"), value: "supplier" },
                      { label: t("admin"), value: "admin" },
                    ]}
                    onChange={(val) => {
                      if (val === "supplier") {
                        setSupplierSelected(true);
                      } else {
                        setSupplierSelected(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {supplierSelected ? null : (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label={t("login")}
                      name="username"
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 3,
                          whitespace: true,
                          message: t("field-min-3") ?? "",
                        },
                      ]}
                    >
                      <Input className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("password")}
                      name="password"
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 4,
                          whitespace: true,
                          message: t("field-min-4") ?? "",
                        },
                      ]}
                    >
                      <Input.Password
                        className="w-full"
                        prefix={<img src={lockIcon} alt="lock" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {position === "admin" && (
                  <Row>
                    <Col span={12}>
                      <Form.Item label={t("branch")} name="branch">
                        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                        <Select
                          options={branchConfig?.map((branch) => ({
                            label: branch.name,
                            value: branch.id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row gutter={24}>
              <Col span={12}>
                {supplierSelected ? (
                  <Form.Item
                    label={t("chat-id")}
                    name="chat_id"
                    rules={[
                      {
                        required: true,
                        type: "number",
                        min: 1,
                        whitespace: true,
                        message: t("required-field") ?? "",
                      },
                    ]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                ) : null}
              </Col>
            </Row>

            <div className="flex justify-between items-center">
              <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
                <div className="flex items-start gap-4">
                  <Form.Item name="is_active" valuePropName="checked" required>
                    <Switch
                      defaultChecked={isActive}
                      onChange={(val) => {
                        isActive = val;
                      }}
                    />
                  </Form.Item>
                  <span className="mt-1">{t("activity-status")}</span>
                </div>
              </ConfigProvider>

              {modal.state === "create" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex items-center"
                  loading={createMutation.isLoading}
                >
                  {t("add-employee")}
                </Button>
              ) : null}

              {modal.state === "update" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex items-center"
                  loading={updateMutation.isLoading}
                >
                  {t("update-employee")}
                </Button>
              ) : null}
            </div>
          </Form>
        ) : null}

        {modal.state === "delete" ? (
          <>
            <div className="absolute top-0 w-full left-0">
              <h1 className="font-semibold text-2xl leading-7 px-10 pt-4 flex items-center justify-between">
                {t("delete-employee")}

                <CloseOutlined
                  style={{ fontSize: "16px" }}
                  onClick={handleCancel}
                />
              </h1>
              <Divider className="my-4" />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 mb-8 mt-[93px]">
              <TrashIcon style={{ color: "#FF1F00" }} />
              <span className="w-80 text-center">
                {t("confirm-delete-employee")}
              </span>

              <div className="flex gap-4">
                <Button
                  className="bg-[#f5f5f5] border-none"
                  type="text"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
                <ConfigProvider theme={{ token: { colorPrimary: "#FF1F00" } }}>
                  <Button
                    className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                    onClick={() => {
                      modal.state === "delete" &&
                        typeof modal.itemId !== "undefined" &&
                        handleDelete(modal.itemId);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </ConfigProvider>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
}
