import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { Product } from "../types/index";

interface Item {
  product: Product;
  quantity: number;
}

interface ICart {
  id: string;
  items: Item[];
  selectedProducts: number[];
}

interface CartState {
  carts: ICart[];
  removeCart: (cardId: string) => void;
  addCart: (cartId: string) => void;
  addItem: (cartId: string, product: Product, quantity?: number) => void;
  removeItem: (cartId: string, productId: number) => void;
  increaseQuantity: (cartId: string, productId: number) => void;
  decreaseQuantity: (cartId: string, productId: number) => void;
}

export const useCart = create<CartState>()(
  persist(
    (set) => ({
      carts: [{ id: "1", items: [], selectedProducts: [] }],
      selectedProducts: [],

      addCart: (cartId) =>
        set((state) => ({
          carts: [
            ...state.carts,
            { id: cartId, items: [], selectedProducts: [] },
          ],
        })),

      removeCart: (cartId) => {
        set((state) => {
          if (state.carts.length > 0) {
            return {
              carts: state.carts.filter((cart) => cart.id !== cartId),
            };
          } else return state;
        });
      },

      addItem: (cartId, product, quantity) => {
        console.log(cartId, product);
        return set((state) => ({
          carts: state.carts.map((cart) =>
            cart.id === cartId
              ? {
                  ...cart,
                  items: [...cart.items, { product, quantity: quantity || 1 }],
                  selectedProducts: [...cart.selectedProducts, product.id],
                }
              : cart,
          ),
        }));
      },

      removeItem: (cartId, productId) =>
        set((state) => ({
          carts: state.carts.map((cart) =>
            cart.id === cartId
              ? {
                  ...cart,
                  items: cart.items.filter(
                    (item) => item.product.id !== productId,
                  ),
                  selectedProducts: cart.selectedProducts.filter(
                    (val) => val !== productId,
                  ),
                }
              : cart,
          ),
        })),

      increaseQuantity: (cartId, productId) =>
        set((state) => ({
          carts: state.carts.map((cart) =>
            cart.id === cartId
              ? {
                  ...cart,
                  items: cart.items.map((item) =>
                    item.product.id === productId
                      ? { ...item, quantity: item.quantity + 1 }
                      : item,
                  ),
                }
              : cart,
          ),
        })),

      decreaseQuantity: (cartId, productId) =>
        set((state) => ({
          carts: state.carts.map((cart) =>
            cart.id === cartId
              ? {
                  ...cart,
                  items: cart.items.map((item) =>
                    item.product.id === productId && item.quantity > 1
                      ? { ...item, quantity: item.quantity - 1 }
                      : item,
                  ),
                }
              : cart,
          ),
        })),
    }),
    {
      name: "multi-cart",
    },
  ),
);
