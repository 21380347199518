import canSee from "@/helpers/can-see";
import InternalServerError from "@/views/internal-server-error";
import Report from "./views";
import Clients from "./views";
import Container from "./components/container";
import ClientsPage from "./views/client";
import ClientDetailPage from "./views/debt-detail";

const clientRoutes = {
  id: "clients",
  title: "clients",
  path: "clients",
  element: <Container of={<Clients />} />,
  errorElement: <InternalServerError />,
  children: [
    {
      id: "debt-detail",
      title: "debt-detail",
      path: "debt-detail/:id",
      element: <ClientDetailPage />,
    },
    {
      id: "debts",
      title: "debts",
      path: "debts",
      element: <Clients />,
    },
    {
      id: "debts-client",
      title: "debts-client",
      path: "debts-client",
      element: <ClientsPage />,
    },
  ],
};

export default clientRoutes;
