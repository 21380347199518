import { Suspense, useEffect } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAuthContext } from "@/contexts/auth-context";
import DefaultLayout from "@/layouts/default-layout";
import useWebSocket from "@/hooks/use-websocket";
import useStyledMessage from "@/hooks/use-styled-message";
import Spinner from "@/components/spinner";
import type { CustomRoute } from "@/types";
import { useTranslation } from "react-i18next";
import { colors } from "@/config/theme";
import SuccessIcon from "@/components/success-icon";
import InfoIcon from "@/components/info-icon";
import useBranchConfig from "@/hooks/use-branch";

interface Props {
  getRoutes: () => CustomRoute[];
}

export default function Root(props: Props): React.ReactElement {
  const { getRoutes } = props;

  const { isAuth } = useAuthContext();
  const { branchConfig, setBranch, branch } = useBranchConfig();

  const { contextHolder, open } = useStyledMessage();
  const { t } = useTranslation();

  const match = useMatch("/");
  const isReportPage = useMatch("/reports");
  const isExpensesPage = useMatch("/expenses");

  const routes = getRoutes();

  const msgPort = useWebSocket();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (branchConfig !== null && branch === null) {
      setBranch(branchConfig[1].id);
    }
  }, [branch, branchConfig, setBranch]);

  useEffect(() => {
    if (typeof msgPort !== "undefined") {
      msgPort.onmessage = (e) => {
        if (e.data.message === "new-order-added") {
          open({
            type: "success",
            content: `${t(e.data.message)}: #${e.data.id}`,
            icon: <SuccessIcon style={{ color: colors.primary }} />,
          });
          const soundeffect = new Audio("/notification-sound.mp3");
          void soundeffect.play();
        }
      };
    }
  }, [msgPort, open, t]);

  useEffect(() => {
    if (typeof msgPort !== "undefined") {
      msgPort.onmessage = (e) => {
        if (e.data.message === "update_order") {
          open({
            type: "info",
            content: `${t(e.data.message)}: #${e.data.id}`,
            icon: <InfoIcon style={{ color: colors.primary }} />,
          });
          const soundeffect = new Audio("/notification-sound.mp3");
          void soundeffect.play();
        }
      };
    }
  }, [msgPort, open, t]);

  if (!isAuth) {
    return <Navigate to="/auth/login" replace />;
  }

  if (isTabletOrMobile && isReportPage === null && isExpensesPage === null) {
    return <Navigate to="/reports" replace />;
  }

  if (match !== null) {
    return <Navigate to="/orders" replace />;
  }

  return (
    <DefaultLayout sidebarRoutes={routes[0].children as CustomRoute[]}>
      <Suspense fallback={<Spinner />}>
        {contextHolder}
        <Outlet />
      </Suspense>
    </DefaultLayout>
  );
}
