export default function Counter(props: {
  count?: number;
  onDecrement: () => void;
  onIncrement: () => void;
}): React.ReactElement {
  const { count, onDecrement, onIncrement } = props;

  return (
    <div className="bg-[#ecedee] rounded-full w-20 p-1 flex items-center">
      <span
        className="bg-white rounded-full px-[7px] cursor-pointer"
        onClick={onDecrement}
        aria-hidden
      >
        -
      </span>
      <span className="flex-1 text-center text-black">{count ?? 0}</span>
      <span
        className="bg-white rounded-full px-[5px] cursor-pointer"
        onClick={onIncrement}
        aria-hidden
      >
        +
      </span>
    </div>
  );
}
